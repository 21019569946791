// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled-pdf {
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .styled-pdf .gridItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .styled-pdf .icon {
    color: #007bff;
    margin-right: 8px;
  }
  
  .styled-pdf .text {
    font-size: 14px;
  }
  
  .styled-pdf .text strong {
    color: #000;
  }
  
  .pdfBtn {
    background-color: #28a745;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .pdfBtn:hover {
    background-color: #218838;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/Admission/Admission.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,WAAW;EACb;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".styled-pdf {\n    font-family: Arial, sans-serif;\n    color: #333;\n  }\n  \n  .styled-pdf .gridItem {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n  }\n  \n  .styled-pdf .icon {\n    color: #007bff;\n    margin-right: 8px;\n  }\n  \n  .styled-pdf .text {\n    font-size: 14px;\n  }\n  \n  .styled-pdf .text strong {\n    color: #000;\n  }\n  \n  .pdfBtn {\n    background-color: #28a745;\n    color: white;\n    padding: 10px 15px;\n    border: none;\n    cursor: pointer;\n    margin-top: 20px;\n  }\n  \n  .pdfBtn:hover {\n    background-color: #218838;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
